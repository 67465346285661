<template>
  <div>
    <el-form class="setForm"
             ref="form"
             :model="form"
             label-width="100px">
      <el-form-item label="PC登录背景">
        <el-col>
          <el-upload :class="imageList.length>0?'hidden brand':'show brand'"
                     style="display:inline-block;"
                     ref="upload"
                     action="#"
                     :file-list="imageList"
                     :limit="1"
                     accept="image/jpeg,image/png,image/jpg"
                     list-type="picture-card"
                     :before-upload="beforeUpload"
                     :on-remove="handleBgRemove"
                     :on-preview="handleBgPreview"
                     :http-request="handleUpload"
                     :on-exceed="handleExceed"
                     >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%"
                 :src="dialogImageUrl"
                 alt="">
          </el-dialog>
          <div class="tipBox">
            <span class="tips">建议尺寸1440*960</span>
            <span class="tips">图片格式为JPG/JPEG/PNG,大小在3M内</span>
          </div>
        </el-col>
      </el-form-item>
      <el-form-item label="登录logo">
        <el-col>
          <el-upload :class="loginLogoList.length>0?'hidden brand':'show brand'"
                     style="display:inline-block;"
                     ref="upload"
                     action="#"
                     :file-list="loginLogoList"
                     :limit="1"
                     accept="image/jpeg,image/png,image/jpg"
                     list-type="picture-card"
                     :on-remove="handleLogoRemove"
                     :on-preview="handleLogoPreview"
                     :on-change="upLoadLoginLogo"
                     :on-exceed="handleExceed">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="loginLogoVisible">
            <img width="100%"
                 :src="loginLogoUrl"
                 alt="">
          </el-dialog>
          <div class="tipBox">
            <span class="tips">建议尺寸250*73</span>
            <span class="tips">图片格式为JPG/JPEG/PNG,大小在5k内</span>
          </div>
        </el-col>
      </el-form-item>
      <el-form-item label="导航logo">
        <el-col>
          <el-upload :class="navLogoList.length>0?'hidden brand':'show brand'"
                     style="display:inline-block;"
                     ref="upload"
                     action="#"
                     :file-list="navLogoList"
                     :limit="1"
                     accept="image/jpeg,image/png,image/jpg"
                     list-type="picture-card"
                     :on-remove="handleNavRemove"
                     :on-preview="handleNavLogoPreview"
                     :on-change="upLoadNavLogo"
                     :on-exceed="handleExceed">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="navLogoVisible">
            <img width="100%"
                 :src="navLogoUrl"
                 alt="">
          </el-dialog>
          <div class="tipBox">
            <span class="tips">建议尺寸152*45</span>
            <span class="tips">图片格式为JPG/JPEG/PNG,大小在5k内</span>
          </div>
        </el-col>
      </el-form-item>
      <el-form-item label="导航小logo">
        <el-col>
          <el-upload :class="navSmallLogoList.length>0?'hidden brand':'show brand'"
                     style="display:inline-block;"
                     ref="upload"
                     action="#"
                     :file-list="navSmallLogoList"
                     :limit="1"
                     accept="image/jpeg,image/png,image/jpg"
                     list-type="picture-card"
                     :on-remove="handleNavSmallRemove"
                     :on-preview="handlesmallPreview"
                     :on-change="upLoadNavSmallLogo"
                     :on-exceed="handleExceed">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="navSmallLogoVisible">
            <img width="100%"
                 :src="navSmallLogoUrl"
                 alt="">
          </el-dialog>
          <div class="tipBox">
            <span class="tips">建议尺寸44*44</span>
            <span class="tips">图片格式为JPG/JPEG/PNG,大小在5k内</span>
          </div>
        </el-col>
      </el-form-item>
      <div class="viewFoot">
        <!-- <el-button type="info">取消</el-button> -->
        <el-button type="primary"
                   size="medium"
                   @click="save">保存</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import * as api from '@/api/companys';
import axios from 'axios';
import * as compress from '@/utils/compress';
export default {
  props: {
    comData: {}
  },
  watch: {
    comData (newVal) {
      if (newVal.backgroundImageList != null) {
        this.imageList = newVal.backgroundImageList;
      }
      if (newVal.bigLogoImageList != null) {
        this.loginLogoList = newVal.bigLogoImageList;
      }
      if (newVal.logoImageList != null) {
        this.navLogoList = newVal.logoImageList;
      }
      if (newVal.smallLogoImageList != null) {
        this.navSmallLogoList = newVal.smallLogoImageList;
      }
    }
  },
  data () {
    return {
      form: {
        backgroundImage: [],
        bigLogoImage: [],
        logoImage: [],
        smallLogoImage: []
      },
      imageList: [],
      loginLogoList: [],
      dialogVisible: false,
      dialogImageUrl: '',
      loginLogoVisible: false,
      loginLogoUrl: '',
      navLogoList: [],
      navLogoVisible: false,
      navLogoUrl: '',
      navSmallLogoList: [],
      navSmallLogoVisible: false,
      navSmallLogoUrl: ''
    };
  },
  methods: {
    handleBgRemove () {
      this.imageList = [];
      this.form.backgroundImage = '';
    },
    handleLogoRemove () {
      this.loginLogoList = [];
      this.form.bigLogoImage = '';
    },
    handleNavRemove () {
      this.navLogoList = [];
      this.form.logoImage = '';
    },
    handleNavSmallRemove () {
      this.navSmallLogoList = [];
      this.form.smallLogoImage = '';
    },
    handleBgPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleLogoPreview (file) {
      this.loginLogoUrl = file.url;
      this.loginLogoVisible = true;
    },
    handleNavLogoPreview (file) {
      this.navLogoUrl = file.url;
      this.navLogoVisible = true;
    },
    handlesmallPreview (file) {
      this.navSmallLogoUrl = file.url;
      this.navSmallLogoVisible = true;
    },
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const isLt2M = file.size / 1024 / 1024 < 3; // 判定图片大小是否小于3MB

        if (!isLt2M) {
          this.$message.error('大小不得超过3M');
          reject();
        }
        const image = new Image();

let
resultBlob = '';

        image.src = URL.createObjectURL(file);
        image.onload = () => {
          // 压缩图片
          resultBlob = compress.onImageDrop(image);
          resolve(resultBlob);
        };
        image.onerror = () => {
          reject();
        };
      });
    },
    handleUpload (eve) {
      this.uploadDone = false;
      const formData = new FormData();

      formData.append('imgFile', eve.file);
      const type = 'multipart/form-data;boundary=--------------------56423498738365';

      axios.post('/v1/common/upload/file', formData, {
        headers: { 'Content-Type': type }
      }).then((res) => {
        this.imageList = [];
        this.imageList.push({ 'name': res.fileName, 'size': '', 'url': res.httpUrl, 'bucketName': res.bucketName, 'key': res.key });
        this.dialogImageUrl = res.httpUrl;
      });
    },
    uploadPublic (file, type) {
      let isOver = 0;

      if (type == '2' || type == '3') {
        isOver = file.size / 1024 / 1024 < 0.2;
        if (!isOver) {
          this.$message.error('大小不得超过200k');
          return;
        }
      } else if (type == '4') {
        isOver = file.size / 1024 / 1024 < 0.005;
        if (!isOver) {
          this.$message.error('大小不得超过5k');
          return;
        }
      }
      const formData = new FormData();

      formData.append('imgFile', file.raw);
      axios.post('/v1/common/upload/file', formData, {
        headers: { 'Content-Type': 'multipart/form-data;boundary=--------------------56423498738365' }
      })
        .then((res) => {
        //   if (type == '1') {
        //     this.imageList = [];
        //     this.imageList.push({ 'name': res.fileName, 'size': '', 'url': res.httpUrl, 'bucketName': res.bucketName, 'key': res.key })
        //     this.dialogImageUrl = res.httpUrl;
        //   } else
          if (type == '2') {
            this.loginLogoList = [];
            this.loginLogoList.push({ 'name': res.fileName, 'size': '', 'url': res.httpUrl, 'bucketName': res.bucketName, 'key': res.key });
            this.loginLogoUrl = res.httpUrl;
          } else if (type == '3') {
            this.navLogoList = [];
            this.navLogoList.push({ 'name': res.fileName, 'size': '', 'url': res.httpUrl, 'bucketName': res.bucketName, 'key': res.key });
            this.navLogoUrl = res.httpUrl;
          } else if (type == '4') {
            this.navSmallLogoList = [];
            this.navSmallLogoList.push({ 'name': res.fileName, 'size': '', 'url': res.httpUrl, 'bucketName': res.bucketName, 'key': res.key });
            this.navSmallLogoUrl = res.httpUrl;
          }

        });
    },
    // uploadImg (file) {
    //   this.uploadPublic(file, '1')
    // },
    upLoadLoginLogo (file) {
      this.uploadPublic(file, '2');
    },
    upLoadNavLogo (file) {
      this.uploadPublic(file, '3');
    },
    upLoadNavSmallLogo (file) {
      this.uploadPublic(file, '4');
    },
    save () {
      if (this.imageList.length > 0) {
        this.form.backgroundImage = JSON.stringify(this.imageList);
      } else {
        this.form.backgroundImage = '';
      }
      if (this.loginLogoList.length > 0) {
        this.form.bigLogoImage = JSON.stringify(this.loginLogoList);
      } else {
        this.form.bigLogoImage = '';
      }
      if (this.navLogoList.length > 0) {
        this.form.logoImage = JSON.stringify(this.navLogoList);
      } else {
        this.form.logoImage = '';
      }
      if (this.navSmallLogoList.length > 0) {
        this.form.smallLogoImage = JSON.stringify(this.navSmallLogoList);
      } else {
        this.form.smallLogoImage = '';
      }
      const params = { request: this.form };

      api.saveBrand(params).then((res) => {
        if (res.code == 0) {
          this.$message.success('保存成功');
          localStorage.setItem('loginImg', JSON.stringify(this.form));
          if (this.form.logoImage) {
            const imgurl = JSON.parse(this.form.logoImage);

            document.getElementsByClassName('sidLgogo')[0].src = imgurl[0].url;
          }
          if (this.form.smallLogoImage) {
            const imgurl = JSON.parse(this.form.smallLogoImage);

            document.getElementsByClassName('smallSidLogo')[0].src = imgurl[0].url;
          }
        }
      });
    },
    handleExceed () {
      this.$message.error('最多上传一张图片');
    }
  }
};
</script>
<style lang="scss" >
.setForm .el-form-item__label {
  color: #606266;
  font-weight: normal;
}
.hidden .el-upload--picture-card{
  display: none;
}
.show .el-upload--picture-card {
  display: block;
}
// .brand .el-upload--picture-card{
//     width: 120px;
//     height:120px;
// }
</style>
<style lang="scss" scoped>
.viewFoot {
  text-align: center;
  .el-button {
    margin: 15px;
    padding: 10px 45px;
  }
}
.tipBox {
  display: inline-block;
  width: 240px;
  line-height: normal;
  vertical-align: top;
  margin-top: 45px;
  margin-left: 5px;
}
.tips {
  font-size: 12px;
  color: #c0c4cc;
  display: block;
  line-height: 20px;
}
</style>



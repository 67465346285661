<template>
 <div class="app-container">
     <el-tabs v-model="activeName" >
      <el-tab-pane label="公司信息" name="1" ><companyset @getComInfo="getComInfo" :comData="comData"></companyset></el-tab-pane>
      <el-tab-pane label="品牌设置" name="2"><brandset @getComInfo="getComInfo" :comData ="comData" ></brandset></el-tab-pane>
      <!-- <el-tab-pane label="权限设置" name="3"></el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import * as api from '@/api/companys';
import companyset from '@/components/companys/companySet';
import brandset from '@/components/companys/brandSet';
export default {
    components: {
        companyset,
        brandset
    },
    mounted() {
      this.getComInfo();
    },
    data() {
        return {
            activeName: '1',
            comData: {}
        };
    },
    methods: {
        // 获取公司信息
        getComInfo() {
          api.getComInfo(
            {
                request: {}
            }).then((res) => {
                this.comData = res;
            });
      }
    }
};
</script>

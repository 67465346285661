<template>
  <div>
    <el-alert
      style="height:30px;"
      title="对公司名称、公司地址、营业执照、统一社会信用代码的更新，将使得公司资料被重新审核，请谨慎修改"
      type="info"
      class="alert"
      :closable="false"
      show-icon
    ></el-alert>

    <el-form
      class="setForm"
      ref="companyForm"
      :model="companyForm"
      label-width="140px"
      :rules="rules"
    >
      <el-form-item prop="comName" label="公司名称">
        <el-col :span="12">
          <el-input v-model="companyForm.comName" placeholder="请输入公司名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item prop="address" label="公司地址">
        <el-col :span="12">
          <el-input v-model="companyForm.address" placeholder="请输入公司地址"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item prop="unifiedCode" label="统一社会信用代码">
        <el-col :span="12">
          <el-input v-model="companyForm.unifiedCode" placeholder="请输入18位统一社会信用代码"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="营业执照">
        <el-row>
          <el-col :span="12">
            <el-upload
              style="display:inline-block"
              ref="upload"
              :class="imageList.length==0?'show uploadBox':'hidden uploadBox'"
              action="#"
              :file-list="imageList"
              :limit="1"
              :before-upload="beforeUpload"
              accept="image/jpeg, image/png, image/jpg, image/pdf"
              list-type="picture-card"
              :on-remove="handleRemove"
              :on-preview="handlePictureCardPreview"
              :http-request="uploadImg"
              :on-exceed="handleExceed"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt />
            </el-dialog>
          </el-col>
        </el-row>

        <div class="tipBox">
          <!-- <span class="tips"
          style="color:red">上传营业执照方可通过审核</span>-->
          <span class="tips">图片格式为jpg/jpeg/png/pdf 大小在2M内</span>
        </div>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item prop="name" label="联系人">
        <el-col :span="12">
          <el-input v-model="companyForm.name" placeholder="请输入联系人"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item prop="telNum" label="联系电话">
        <el-col :span="12">
          <el-input v-model.number="companyForm.telNum" placeholder="请输入电话"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item prop="comMailbox" label="邮箱">
        <el-col :span="12">
          <el-input v-model="companyForm.comMailbox" placeholder="请输入邮箱"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item prop="comSize" label="公司规模">
        <el-col :span="12">
          <el-select v-model="companyForm.comSize" style="width:100%" placeholder="请选择">
            <el-option
              v-for="(item,index) in comSizeList"
              :key="index"
              :label="item.dictName"
              :value="item.dictId"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item prop="industry" label="所属行业">
        <el-col :span="12">
          <el-select v-model="companyForm.industry" style="width:100%" placeholder="请选择">
            <el-option-group
              v-for="(group,index) in industryList"
              :key="index"
              :label="group.label">
              <el-option
                v-for="(item,i1) in group.children"
                :key="i1"
                :label="item.label"
                :value="item.label">
              </el-option>
            </el-option-group>
          </el-select>
        </el-col>
      </el-form-item>
      <div class="viewFoot">
        <!-- <el-button type="info"
        size="medium">取消</el-button>-->
        <el-button type="primary" size="medium" @click="save">保存</el-button>
        <!-- <el-button type="primary"
        size="medium">提交</el-button>-->
      </div>
    </el-form>
  </div>
</template>
<script>
import * as api from '@/api/companys';
import axios from 'axios';
export default {
  props: {
    comData: {}
  },
  watch: {
    comData(newVal) {
      this.companyForm = newVal;
      if (this.companyForm.comSize == null) {
        this.companyForm.comSize = '';
      }
      if (this.companyForm.industry == null) {
        this.companyForm.industry = '';
      }
      if (this.companyForm.businessLicenseList != null) {
        this.imageList = this.companyForm.businessLicenseList;
      }
      if (this.companyForm.telNum) {
        this.companyForm.telNum = parseInt(this.companyForm.telNum);
      }
    }
  },
  data() {
    return {
      companyForm: {
        comName: '',
        address: '',
        unifiedCode: '',
        businessLicense: '',
        name: '',
        telNum: '',
        comMailbox: '',
        comSize: '',
        industry: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      comSizeList: [],
      industryList: window.$industryList,
      imageList: [],
      rules: {
        comName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        unifiedCode: [
          {
            required: true,
            message: '请输入统一社会信用代码',
            trigger: 'blur'
          },
          {
            pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
            message: '请输入正确统一社会信用代码',
            trigger: 'blur'
          }
        ],
        name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        telNum: [
          {
            type: 'number',
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          },
          {
            pattern: /^\d{3,11}$|^\d{3,11}$/,
            message: '请输入正确手机号码',
            trigger: 'blur'
          }
        ],
        comMailbox: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: 'blur,change'
          }
        ],
        comSize: [{ required: true, message: '请选择规模', trigger: 'change' }],
        industry: [{ required: true, message: '请选择行业', trigger: 'change' }]
        // businessLicenseList:[{ required: true, message: '请上传营业执照', trigger: 'change' }]
      }
    };
  },
  mounted() {
    this.$nextTick(function() {
      this.comSizeList = this.$store.state.app.dict.filter((item) => item.groupId == 'comSize');
    });
  },
  methods: {
    handleRemove() {
      this.imageList = [];
      this.companyForm.businessLicense = '';
      this.companyForm.businessLicenseList = '';
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
       return isLt2M;
    },
    // 上传营业执照
    uploadImg(event) {
      const formData = new FormData();

      formData.append('imgFile', event.file);
      axios
        .post('/v1/common/upload/file', formData, {
          headers: {
            'Content-Type':
              'multipart/form-data;boundary=--------------------56423498738365'
          }
        })
        .then((res) => {
          this.imageList = [];
          this.imageList.push({
            name: res.fileName,
            size: '',
            url: res.httpUrl,
            bucketName: res.bucketName,
            key: res.key
          });
          this.dialogImageUrl = res.httpUrl;
        });
    },
    handleExceed() {
      this.$message.error('只能上传一张营业执照');
    },
    // 保存公司信息
    save() {
      if (this.imageList.length == 0) {
        this.$message.error('上传营业执照方可通过审核');
        this.imageList = [];
        return;
      }
      if (this.imageList.length > 0) {
        this.companyForm.businessLicense = JSON.stringify(this.imageList);
      }
      this.$refs['companyForm'].validate((valid) => {
        if (valid) {
          const params = { request: this.companyForm };

          api.save(params).then((res) => {
            if (res.code == 0) {
              this.$message.success('保存成功');
              this.$emit('getComInfo');
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" >
.hidden .el-upload--picture-card{
  display: none;
}
.show .el-upload--picture-card {
  display: block;
}
.setForm {
  margin-top: 10px;
}
.alert {
  width: 55.8%;
}
@media (max-width:1300px){
  .alert {
    width: 61%;
  }
};
@media (max-width:1100px){
  .alert {
    width: 72%;
  }
};
.setForm .el-form-item__label {
  color: #606266;
  font-weight: normal;
}
</style>
<style lang="scss" scoped>
.tipBox {
  display: inline-block;
  width: 240px;
  line-height: normal;
  vertical-align: top;
  margin-top: 20px;
  margin-left: 5px;
}
.tips {
  font-size: 12px;
  color: #c0c4cc;
  display: block;
  line-height: 20px;
}
.viewFoot {
  text-align: center;
  .el-button {
    margin: 15px;
    padding: 10px 45px;
  }
}
</style>


